.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #999;
}
.carousel .thumb {
  max-height: 60px;
  margin-right: 0;
}
